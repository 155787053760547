<template>
  <div class="staff_management_add">
    <div class="content">
      <div class="title">
        <span>新增子账号</span>
      </div>
      <div class="form">
        <el-form ref="mainForm" :rules="rules" :model="formData" label-width="8rem" class="form1">
          <el-form-item label='用户账号' prop="phone">
            <el-input v-model='formData.phone' maxlength="15" placeholder="请输入用户账号"/>
          </el-form-item>
          <el-form-item label='用户姓名' prop="name">
            <el-input v-model='formData.name' maxlength="10" placeholder="请输入用户姓名"/>
          </el-form-item> 
          <!-- <el-form-item label='角色' prop="role">
            <el-select v-model="formData.role" placeholder="请选择角色" style="width:100%">
              <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item> -->
          <el-form-item label='邮箱地址' prop="email">
            <el-input v-model='formData.email' placeholder="请输入收票人电子邮箱"/>
          </el-form-item>
          <el-form-item label='开票点' prop="pointId">
             <el-select v-model="formData.pointId" placeholder="请选择开票点" style="width:100%">
              <el-option v-for="item in pointList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label='备注' prop="remark">
            <el-input v-model='formData.remark' maxlength="50" placeholder="请输入备注"/>
          </el-form-item>
          <div class="button">
            <el-button type="primary" class="btn btn_confirm" @click="submitForm"  :loading='isLoading'>确定</el-button>
            <el-button class="btn" @click="back">取消</el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "addStaff",
  components: {
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      if(value) {
        var pass=false;
        const mobile = /^(((\+86)|(\+886)|(\+852)|(\+853))\s+)?((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|190)\d{8}$/;
        if(mobile.test(value)){
          pass=true;
        }
        if (!pass) {
          return callback(
              new Error("请输入正确的用户账号")
          )
        }
      }
      callback()
    }
    return {
      formData: {
        name: '',//员工姓名
        phone: '',	//手机号
        pointId:'',
        email:'',
        remark: '',	//备注
      },
      isLoading: false,
      roleList:[],  //角色列表
      pointList:[], //开票点
      rules: {
        name: [
          { required: true, message: "请输入用户姓名" },
          { min: 1, max: 10, message: "用户姓名在 1 到 10 个字符", trigger: 'blur' },
          {pattern: "^[^ ]+$",message:'用户姓名不能含空格'}
        ],
        phone: [{ required: true, message: "请输入用户账号" },{ validator: checkPhone, trigger: 'blur' }],
        email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { pattern: '^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$', message: '请输入正确的邮箱', trigger: 'blur' }
          ],
        role :[{ required: true, message: "请选择角色" , trigger: 'change' },],
        pointId:[{ required: true, message: "请选择开票点", trigger: 'change'  },],
        remark:[
          { min: 1, max: 50, message: "备注在 1 到 50 个字符", trigger: 'blur' }
        ]
      },
    };
  },
  methods: {
    submitForm() {
      this.$refs.mainForm.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          // ,'createUserId':this.getToken('userId'),
          this.$store.dispatch("AddAccount", {'agencyCompanyId':this.getToken('agencyCompanyId'),...this.formData}).then(res => {
            if (res.success) {
              this.isLoading = false;
              einvAlert.success("提示",'员工的初始密码为：ZGRS123@')
              this.back()
            } else {
              einvAlert.error("提示",res.msg)
              this.isLoading = false
            }
          }).catch(err=>{
            this.isLoading = false;
          })
        }
      });
    },
    
    initPointList(){
      this.$store.dispatch("GetPointList",{}).then(res => {
        if (res.success) {
            this.pointList = res.data
        } else {
          einvAlert.error("提示",res.msg)
        }
      }).catch(err => {
        // einvAlert.error("提示",err)
        console.dir(err)
      })
    },
    // initRoleList(){
    //   this.$store.dispatch("FindRoleList", {
    //     userId: this.getToken("userId"),
    //     companyId: this.getToken("companyId"),
    //   }).then(res => {
    //     if (res.success) {
    //         this.roleList = res.data
    //     } else {
    //       einvAlert.error("提示",res.msg)
    //     }
    //   }).catch(err => {
    //     // einvAlert.error("提示",err)
    //     console.dir(err)
    //   })
    // },
    back() {
      this.$router.back();
    },
  },
  mounted() {
    //  this.initRoleList();
     this.initPointList();
  }
};
</script>

<style lang='less' scoped>
.content {
  padding:  20px;
  height: calc(100% - 2rem);
  margin: 0 auto;
  background: #fff;
  .title {
    border-bottom: 1px solid #dcdcdc;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    margin: 0;
    padding-bottom: 1rem;
    color: #333333;
  }
  .form{
    text-align: center;
    padding-top: 16px;
    .form-inline{
      margin: 20px auto;
      .form-inline-left{
        width: 100px;
        text-align: right;
        font-size: 16px;
        font-weight: normal;
        color: #333333;
        display: inline-block;
        margin-right: 20px;
      }
      .form-inline-input{
        display: inline-block;
        width: 360px;
      }
    }
    .button {
      text-align: center;
      padding-top: 27px;
      .btn {
        width: 120px;
        height: 40px;
      }
      .btn_confirm{
        margin-right: 27px;
      }
    }
  }
}
</style>
<style lang='less' >
  .staff_management_add{
    .form1{
      margin: 40px auto;
      width: 505px;
      // input{
      //  height: 48px !important;
      // }
    }
  }
</style>
